<template>
  <div>
    <Intro
      v-for="(company, index) of data"
      :key="index"
      :backgroundImage="company.backgroundImage"
      :companyTitle="company.companyTitle"
      :companyDescription="company.companyDescription"
      :actionLink="company.actionLink"/>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";
import Intro from '@/components/Intro.vue'
import introData from '@/data/intro'
import hengHuatLogo from '@/assets/images/henghuat/heng_huat_1.jpeg'
export default {
  name: "Home",
  components: {
    HelloWorld,
    Intro
  },
  data(){
    return{
      logo: hengHuatLogo,
      data: {...introData}
    }
  }
};
</script>
