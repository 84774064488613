<template>
    <div class="nav-root">
    <b-navbar toggleable="lg" class="nav-container justify-content-end" >
        <b-navbar-brand v-if="isDetailPage" class="mr-auto">
            <div class="logo-container">
                <img :src="companyLogo" alt="companyName" />
            </div>
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse" class="navbar-light "></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="ml-auto align-items-start" v-if="isDetailPage">
                <b-nav-item to="/">{{$t('NAV.HOME')}}</b-nav-item>
                <b-nav-item @click="scrollInnerPage('our-service')" v-if="hasServiceSection">{{$t('NAV.OUR_SERVICES')}}</b-nav-item>
                <b-nav-item @click="scrollInnerPage('why-us')" v-if="hasWhyUsSection">{{$t('NAV.WHY_US')}}</b-nav-item>
                <b-nav-item @click="scrollInnerPage('about')" v-if="hasAboutSection">{{$t('NAV.ABOUT')}}</b-nav-item>
                <b-nav-item @click="scrollInnerPage('contact-us')" v-if="hasContactUsSection">{{$t('NAV.CONTACT_US')}}</b-nav-item>
            </b-navbar-nav>
            <b-navbar-nav 
                class="flex-row-start dropdown-style"
                :class="{'ml-auto':!isDetailPage}">
                <b-nav-item-dropdown :text="$t('NAV.COMPANY')" class="text-left" right>
                    <b-dropdown-item to="heng-huat"> 
                            HENG HUAT FRUITS
                    </b-dropdown-item>
                    <b-dropdown-item to="hpo-fruits">
                            HPO FRUITS
                    </b-dropdown-item>
                    <b-dropdown-item to="dragon-global">
                            DRAGON GLOBAL
                    </b-dropdown-item>
                    <b-dropdown-item to="el-cold-chain">
                            EL COLD CHAIN LOGISTIC
                    </b-dropdown-item>
                </b-nav-item-dropdown>
            </b-navbar-nav>
            <b-navbar-nav class="flex-row-start language-container">
                <b-nav-item 
                    @click="updateLocale('en')" 
                    class="selection"
                    :class="{'selected':selectedLanguage('en')}">🇺🇸 EN
                </b-nav-item>
                <b-nav-item 
                    @click="updateLocale('my')" 
                    class="selection"
                    :class="{'selected':selectedLanguage('my')}">🇲🇾 BM
                    </b-nav-item>
                <b-nav-item 
                    @click="updateLocale('zh-cn')" 
                    class="selection"
                    :class="{'selected':selectedLanguage('zh-cn')}">🇨🇳 中文
                </b-nav-item>
            </b-navbar-nav>
        </b-collapse>
    </b-navbar>

  </div>
</template>

<script>
import {allRoutes, routesObj} from '@/enums/common'
import hengHuatLogo from '@/assets/logo/henghuat_transparent.png'
import dragonGlobalLogo from '@/assets/logo/dragon_fruit.png'
import elColdLogo from '@/assets/logo/el_cold_transparent.png'
import hpoLogo from '@/assets/logo/hpo_transparent.png'
import navData from '@/data/nav'
import {mapGetters, mapActions} from 'vuex'
export default {
    name:"NavBar",
    data(){
        return{
            hasServiceSection:false,
            hasAboutSection:false,
            hasWhyUsSection:false,
            hasContactUsSection:false,
            navData:navData
        }
    },
    watch:{
        '$route':{
            handler(data){
                this.navData.forEach(nav =>{
                    if(nav.path === data.path){
                        this.hasServiceSection = nav.hasService ? nav.hasService : false
                        this.hasAboutSection = nav.hasAbout ? nav.hasAbout: false
                        this.hasWhyUsSection = nav.hasWhyUs ? nav.hasWhyUs : false
                        this.hasContactUsSection = nav.hasContactUs ? nav.hasContactUs : false
                    }
                })
            },
            deep:true
        }
    },
    computed:{
        ...mapGetters(['language/getLanguage']),
        isDetailPage(){
            return allRoutes.some(route => route === this.$route.path)
        },       
        companyLogo(){
            switch(this.$route.path){
                case routesObj.hengHuat.path:
                    return hengHuatLogo;

                case routesObj.hpo.path:
                    return hpoLogo;

                case routesObj.dragonGlobal.path:
                    return dragonGlobalLogo;

                case routesObj.elColdChain.path:
                    return elColdLogo;

                default:
                    return hengHuatLogo;
            }
        },
    },
    methods:{
        ...mapActions(['language/updateLanguage']),
        selectedLanguage(language){
            return language.toLowerCase() === this['language/getLanguage']
        },
        updateLocale(language){
            this['language/updateLanguage']({language})
            this.$root.$i18n.locale = language
        },
        scrollInnerPage(id){
            const domElement = document.getElementById(id)
            if(domElement)            
                domElement.scrollIntoView({behavior:"smooth"});
        },
    },
}
</script>

<style lang="scss" scoped>
.nav-root{
    position:fixed; 
    width:100%; 
    z-index:999;

    .nav-item{
        transition: .2s all;

        &:hover{
            border-bottom: 1px solid white;
        }
    }
}


.logo-container{
    max-width: 30px;

    img{
        width:100%;
        height:100%;
        object-fit: contain;
    }
}

.language-container{
    margin-left: 1.5rem;
    padding-left: 1.5rem;  
    border-left: 1px solid $white;  

    .nav-item{
        transition: .2s all;
        border-radius: 5px;
        padding-left: 5px;
        padding-right: 5px;

        &:hover, &.selected{
            background: #01365c;
            border-bottom: none;
        }
    }
}


@media screen and (max-width: 600px){
    .flex-row-start{
        flex-direction: row;
        align-items: flex-start;
    }

    .language-container{
        margin-left: 0;
        padding-left: 0;
        border-left: none;

        .selection{
            margin-right: 15px;
        }

    }
}

</style>