<template>
  <footer class="py-3">
      Copyright © {{currentYear}} {{company}}. All rights reserved.
  </footer>
</template>

<script>
export default {
    name:"Footer",
    computed:{
        currentYear(){
            return new Date().getFullYear()
        },
        company(){
            const {path, name} = this.$route
            if(path && path !== "/")
                return name
        }
    }
}
</script>

<style lang="scss" scoped>
footer{
    background-color: #ebebeb;
}
</style>