const {routesObj} = require('../enums/common')

module.exports = [
    {
        path: routesObj.hengHuat.path,
        hasAbout: true,  
        hasContactUs:true      
    },
    {
        path: routesObj.hpo.path,
        hasAbout: true,        
    },
    {
        path: routesObj.dragonGlobal.path,
        hasAbout: true,        
    },
    {
        path: routesObj.elColdChain.path,
        hasWhyUs:true,
        hasService:true,
        hasContactUs:true       
    },
]