const routesObj ={
    home:{
        path:"/",
        name:"Home"
    },
    hengHuat:{
        path:"/heng-huat",
        name:"Heng Huat Sdn Bhd",
    },
    hpo:{
        path:"/hpo-fruits",
        name:"HPO Fruits Sdn Bhd"
    },
    dragonGlobal:{
        path:"/dragon-global",
        name:"Dragon Global Sdn Bhd"
    },
    elColdChain:{
        path:"/el-cold-chain",
        name:"El Cold Chain Logistic Sdn Bhd"
    },
}

module.exports = {
    routesObj,
    language:{
        english: "en",
        chinese: "zh-cn"
    },
    allRoutes:[        
        routesObj.hengHuat.path, 
        routesObj.hpo.path, 
        routesObj.dragonGlobal.path, 
        routesObj.elColdChain.path, 
    ]
}