module.exports = {
    TITLE:{
        HEADING_ONE:"Heading One",
        ABOUT_US:"About Us",
        OUR_SERVICES: "Our Services",
        WHY_US:"Why Us",
        CONTACT_US:"Contact Us"
    },
    NAV:{
        COMPANY: "Company",
        ABOUT: "About",
        HOME: "Home",
        OUR_SERVICES: "Our Services",
        WHY_US:"Why Us",
        CONTACT_US:"Contact Us"
    },
    ACTION:{
        LEARN_MORE: "Learn More",
        CONTACT_US: "Contact Us",
        EMAIL: "Email",
        PHONE_NO: "Phone No.",
        ADDRESS: "Address"
    },
    COMPANY:{
        DESCRIPTIONS:{
            HENG_HUAT: "Wholesale Import & Export of Fruit 🍊",
            HPO_FRUITS: "Fruit Retail and Wholesale 🍑",
            DRAGON_GLOBAL:"Dragon Fruit Farm, Export and Fruit Packaging 📦",
            EL_COLD_CHAIN_LOGISTIC: "Efficacious Logistic, Excellent Service 🛳"
        },
        ESTABLISHED_YEAR:"We has been established since {year}.",
        INFO:{
            HPO_FRUITS:"Welcome to HPO Fruits Sdn Bhd. We are subsidiaries from Heng Huat Fruits Sdn Bhd<br /> We are one of the biggest fruits retailer and wholesaler in Selayang",
            HENG_HUAT:"Welcome to Heng Huat Fruits Sdn Bhd. <br /> We are one of Malaysia's leading wholesale fruit suppliers based in Johor.",            
            DRAGON_GLOBAL:"Welcome to Dragon Global Sdn Bhd. We are subsidiaries from Heng Huat Fruits Sdn Bhd.<br /> We provide exporting and packaging of Dragon Fruits from our large scale of Dragon Fruit Farm",
            EL_COLD_CHAIN_LOGISTIC:"We are subsidiaries from Heng Huat Fruits Sdn Bhd."
        },   
        SERVICES:{
            EL_COLD_CHAIN_LOGISTIC:{
                TITLE_1:'Customs Brokerage - Air and Sea Cargo',
                DESCRIPTION_1 : "We processed more than 35,000 TEUs per year and more than 2000 tonnes to support your business with consistency, reliability and flexibility.",
                TITLE_2:'Freight Forwarding',
                DESCRIPTION_2 : "We provide a most competitive rate in almost any location in the world for your logistical needs",
                TITLE_3:'Container Haulage',
                DESCRIPTION_3 : "We served with 50 prime movers & 250 trailers, We are ranked top 15 at Port Klang by Association of Malaysian Haulage",
                TITLE_4:'Cold Truck',
                DESCRIPTION_4 : "Our Cold Transport able to support our client's needs of importing and exporting of F&B goods",
                TITLE_5:'Cold Storage Warehousing',
                DESCRIPTION_5 : "We provide more than 4500 pallets with the division of Chilled (0 °C) and Frozen (-25 °C)",            
            }
        },
        PURPOSE:{
            EL_COLD_CHAIN_LOGISTIC:{
                TITLE_1:'Advanced Pallet Racking Shuttle System',
                DESCRIPTION_1 : "We provide you a high density storage together with remote control system",
                TITLE_2:'Ethylene Gas Removal',
                DESCRIPTION_2 : "Hi-tech filter extends storage life, prolonging the lifespan of fruits and vegetables, keep products fresh and safe to consume",
                TITLE_3:'Halal-Certified',
                DESCRIPTION_3 : "We followed Good Practice Management (GMP) and provide you a reliable and excellent service",
                TITLE_4:'Integrated Warehousing Solutions',
                DESCRIPTION_4 : "Storage location barcode system and Cloud WMS System",
                TITLE_5:'Reliable Securiy Service',
                DESCRIPTION_5 : "Our storage is under 24-hour CCTV surveillance and able to monitor remotely through mobiles",            
                TITLE_6:'Smart Temperature Monitoring System',
                DESCRIPTION_6 : "Our systems come with alerts and auto-triggered notification via email or phone calls when detecting abnormal change of temperature",            
            }
        },
        HISTORY:{
            HENG_HUAT:{
                H_1:'In 2001, Mr. Tee Chian Huat founded the company, which was named T HENG HWA TRADING, a company specializing in supplying fresh and high quality fruits to entire Malaysia.',
                H_2:'In 2010, the company started a fruit wholesale business and brought in fresh and high quality imported fruits from China and Thailand.',
                H_3:'In 2014, the company changed its name to HENG HUAT FRUTIS SDN BHD and was officially established and started its fruit retail and wholesale services in Johor, and the scale of operation has continued to expand.',
                H_4:"To date, we have consistently ensured the best quality, safety and traceability of origin in the packing and shipping of our fruits. We sell fresh and seasonal quality products at premium prices.",
                H_5:"Our subsidiary EL Cold Chain Logistics Sdn Bhd was born from the use of standard compliant and safe packaging. We ensure reliable means of transporting goods and advanced pallet rack shuttle system provides you with high density storage as well as remote control system to ensure fresh fruits are transported to our customers.",
                H_6:"In order to maintain the loyalty of our customers, we respect the defined delivery deadlines. Our team of professionals is efficient and able to meet all special requirements. These teams will assist you throughout the purchase process and provide you with excellent after-sales service, as well as a permanent follow-up by telephone. With our expertise, our knowledge in this field and our strong motivation, HENG HUAT FRUTIS SDN BHD seeks the quality of its products, wishing to satisfy the needs of its customers, while ensuring the safety of its products for distributors and consumers.",
            },
            HPO_FRUITS:{
                H_1:"We also provide importing service in Fresh Fruits"
            },
            DRAGON_GLOBAL:{
                H_1:"We having 200 HEKTAR area of land",
                H_2:"We also provide wholesaling service to Local Merchants and exporting service to Singapore, Indonesia, Dubai and Cambodia"
            },           
        }
        
    },


}