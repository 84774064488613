<template>
  <div id="app">
    <NavBar/>
    <transition name="fade" mode="out-in">
      <router-view class="pt-55"/>
    </transition>
    <Footer />
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Footer from './components/Footer.vue'
import NavBar from './components/NavBar.vue'

export default {
  name:"Home", 
  components:{
    NavBar,
    Footer
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');
@import "~@/assets/scss/vendors/bootstrap-vue/index";
@import "~@/assets/scss/main";
html {overflow-x: hidden; overflow-y: auto;}
#app {
  font-family: 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow: hidden;
  width: 100vw;
  scroll-behavior: smooth;
}

a {
  color: #2c3e50;
  text-decoration: none;

  &:hover{
    text-decoration: none !important;
  }
}

.pt-55{
  padding-top: 55px;
}

.fade-enter, .fade-leave-to{
  opacity: 0;
  transform: translateX(2em);
}

.fade-enter-active, .fade-leave-active{
  transition: all .3s ease;
}

</style>
