module.exports = {
    TITLE:{
        HEADING_ONE:"标题一",
        ABOUT_US:"关于我们",
        OUR_SERVICES: "我们的服务",
        WHY_US:"我们的优势",
        CONTACT_US:"联系我们"
    },
    NAV:{
        COMPANY: "公司",
        ABOUT: "关于我们",
        HOME: "主页",
        OUR_SERVICES: "我们的服务",
        WHY_US:"我们的优势",
        CONTACT_US:"联系我们"
    },
    ACTION:{
        LEARN_MORE: "了解更多",
        CONTACT_US: "联系我们",
        EMAIL: "电子邮件",
        PHONE_NO: "电话号码",
        ADDRESS: "地址"
    },
    COMPANY:{
        DESCRIPTIONS:{
            HENG_HUAT: "水果批发进出口 🍊",
            HPO_FRUITS: "水果零售和批发 🍑",
            DRAGON_GLOBAL:"园口种植火龙果，出口水果 & 包装水果 📦",
            EL_COLD_CHAIN_LOGISTIC: "高效物流，优质服务 🛳"
        },
        ESTABLISHED_YEAR:"我们成立于 {year}.",
        INFO:{
            HPO_FRUITS:"欢迎来到 HPO Fruits Sdn Bhd. 我们是 Heng Huat Fruits Sdn bhd 的分公司<br /> 我们是士拉央最大的水果零售商和批发商之一",
            HENG_HUAT:"欢迎来到 Heng Huat Fruits Sdn Bhd. <br /> 我们是位于柔佛的马来西亚领先的水果批发供应商之一",            
            DRAGON_GLOBAL:"欢迎来到 Dragon Global Sdn Bhd. 我们是 Heng Huat Fruits Sdn bhd 的分公司<br /> 我们拥有大型火龙果农场以及提供火龙果的出口和包装",
            EL_COLD_CHAIN_LOGISTIC:"我们是 Heng Huat Fruits Sdn bhd 的分公司"
        },   
        SERVICES:{
            EL_COLD_CHAIN_LOGISTIC:{
                TITLE_1:'报关业务 - 空运和海运',
                DESCRIPTION_1 : "我们每年处理超过 35,000 个标准箱和超过 2000 吨，以一致、可靠和灵活的方式支持您的业务。",
                TITLE_2:'货运代理',
                DESCRIPTION_2 : "我们在世界上几乎任何地方都提供最具竞争力的价格来满足您的物流需求",
                TITLE_3:'集装箱运输',
                DESCRIPTION_3 : "我们为 50 辆原动机和 250 辆拖车提供服务，我们在马来西亚运输协会的巴生港排名前 15",
                TITLE_4:'冷车服务',
                DESCRIPTION_4 : "我们的冷运服务能够支持我们客户的进出口食品和饮料的需求",
                TITLE_5:'冷库仓储',
                DESCRIPTION_5 : "我们提供超过 4500 个托盘，分为冷藏 (0 °C) 和冷冻 (-25 °C)",            
            }
        },
        PURPOSE:{
            EL_COLD_CHAIN_LOGISTIC:{
                TITLE_1:'先进的托盘货架穿梭系统',
                DESCRIPTION_1 : "我们为您提供高密度存储以及远程控制系统",
                TITLE_2:'去除乙烯气体',
                DESCRIPTION_2 : "高科技过滤器延长储存寿命，延长果蔬寿命，保持产品新鲜安全食用",
                TITLE_3:'清真认证',
                DESCRIPTION_3 : "我们遵循 Good Practice Management (GMP) 并为您提供可靠而优质的服务",
                TITLE_4:'综合仓储解决方案',
                DESCRIPTION_4 : "存储位置条码系统和云端WMS系统",
                TITLE_5:'可靠的安全服务',
                DESCRIPTION_5 : "我们的存储处于 24 小时闭路电视监控下，并能够通过手机远程监控",            
                TITLE_6:'智能温度监测系统',
                DESCRIPTION_6 : "当检测到温度异常变化时，我们的系统会通过电子邮件或电话发出警报和自动触发通知",            
            }
        },
        HISTORY:{
            HENG_HUAT:{
                H_1:'2001 年，郑呈发先生创办了公司，公司起名为 T HENG HWA TRADING，是一家专门向全马提供新鲜和高质量水果的公司。',
                H_2:'2010 年，公司开始经营起水果批发，并从中国和泰国引入新鲜和高质量的进口水果。',
                H_3:'2014 年, 公司改名为 HENG HUAT FRUTIS SDN BHD，公司正式位于柔佛州成立和开始水果零售与批发的服务，经营规模也持续扩大。',
                H_4:"至今，我们一贯保证水果的包装和运输的最佳品质、安全和原产地可追溯性。我们以优质价格销售新鲜和季节性的优质产品。",
                H_5:"我们的子公司EL Cold Chain Logistics Sdn Bhd 的诞生，就是使用符合标准的安全包装。我们确保用可靠的手段运输货物和先进的托盘货架穿梭系统为您提供高密度存储以及远程控制系统，确保把新鲜的水果运输给客户。",
                H_6:"为了保持客户的忠诚度，我们尊重规定的交货期限。 我们的专业团队效率高，能够满足所有的特殊要求。这些团队将在整个购买过程中协助您，并为您提供优质的售后服务，以及长期的电话跟踪。凭借我们的专业知识，我们在这个领域的知识和我们强大的动力，HENG HUAT FRUTIS SDN BHD 寻求产品的质量，希望满足客户的需求，同时确保对分销商和消费者的产品安全。",
            },
            HPO_FRUITS:{
                H_1:"我们也提供水果进口服务"
            },
            DRAGON_GLOBAL:{
                H_1:"占地 200 HEKTAR",
                H_2:"除了批发给本地商家，我们也有提供出口服务给新加坡，印度尼西亚，迪拜和柬埔寨"
            },           
        }
        
    },


}