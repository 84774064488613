module.exports = {
    TITLE:{
        HEADING_ONE:"Tajuk Satu",
        ABOUT_US:"Tentang Kita",
        OUR_SERVICES: "Perkhidmatan Kami",
        WHY_US:"Kenapa Kita",
        CONTACT_US:"Hubungi Kami"
    },
    NAV:{
        COMPANY: "Syarikat",
        ABOUT: "Tentang",
        HOME: "Halaman Utama",
        OUR_SERVICES: "Perkhidmatan Kami",
        WHY_US:"Kenapa Kita",
        CONTACT_US:"Hubungi Kami"
    },
    ACTION:{
        LEARN_MORE: "Ketahui Lebih Lanjut",
        CONTACT_US: "Hubungi Kami",
        EMAIL: "E-mel",
        PHONE_NO: "Telefon No.",
        ADDRESS: "Alamat"
    },
    COMPANY:{
        DESCRIPTIONS:{
            HENG_HUAT: "Borong Import & Eksport Buah 🍊",
            HPO_FRUITS: "Peruncitan dan Borong Buah-buahan 🍑",
            DRAGON_GLOBAL:"Ladang Buah Naga, Eksport dan Pembungkusan Buah-buahan 📦",
            EL_COLD_CHAIN_LOGISTIC: "Logistik Berkesan, Perkhidmatan Cemerlang 🛳"
        },
        ESTABLISHED_YEAR:"Kami telah ditubuhkan sejak tahun {year}.",
        INFO:{
            HPO_FRUITS:"Selamat datang ke HPO Fruits Sdn Bhd dan Kami ialah anak syarikat dari Heng Huat Fruits Sdn Bhd<br /> Kami adalah salah satu peruncit dan pemborong buah-buahan terbesar di Selayang",
            HENG_HUAT:"Selamat datang ke Heng Huat Fruits Sdn Bhd. <br /> Kami adalah salah satu pembekal buah-buahan borong terkemuka di Malaysia yang berpangkalan di Johor.",            
            DRAGON_GLOBAL:"Selamat datang ke Dragon Global Sdn Bhd. <br /> Kami menyediakan pengeksportan dan pembungkusan Buah Naga dari Ladang Buah Naga skala besar kami",
            EL_COLD_CHAIN_LOGISTIC:"Kami adalah anak syarikat dari Heng Huat Fruits Sdn Bhd"
        },   
        SERVICES:{
            EL_COLD_CHAIN_LOGISTIC:{
                TITLE_1:'Pembrokeran Kastam - Kargo Udara dan Laut',
                DESCRIPTION_1 : "Kami memproses lebih daripada 35,000 TEU setahun dan lebih daripada 2000 tan untuk menyokong perniagaan anda dengan konsisten, kebolehpercayaan dan fleksibiliti.",
                TITLE_2:'Pengangkutan Barang',
                DESCRIPTION_2 : "Kami menyediakan kadar yang paling kompetitif di hampir mana-mana lokasi di dunia untuk keperluan logistik anda",
                TITLE_3:'Pengangkutan Kontena',
                DESCRIPTION_3 : "Kami berkhidmat dengan 50 penggerak utama & 250 treler, Kami berada di kedudukan 15 teratas di Pelabuhan Klang oleh Persatuan Pengangkutan Malaysia",
                TITLE_4:'Lori Sejuk',
                DESCRIPTION_4 : "Pengangkutan Sejuk kami mampu menyokong keperluan pelanggan kami untuk mengimport dan mengeksport barangan F&B",
                TITLE_5:'Gudang Penyimpanan Sejuk',
                DESCRIPTION_5 : "Kami menyediakan lebih daripada 4500 palet dengan pembahagian Chilled (0 °C) dan Frozen (-25 °C)",            
            }
        },
        PURPOSE:{
            EL_COLD_CHAIN_LOGISTIC:{
                TITLE_1:'Sistem Ulang-alik Racking Pallet Termaju',
                DESCRIPTION_1 : "Kami menyediakan anda storan berketumpatan tinggi bersama-sama dengan sistem kawalan jauh",
                TITLE_2:'Penyingkiran Gas Etilena',
                DESCRIPTION_2 : "Penapis berteknologi tinggi memanjangkan hayat penyimpanan, memanjangkan jangka hayat buah-buahan dan sayur-sayuran, memastikan produk segar dan selamat untuk dimakan",
                TITLE_3:'Diperakui Halal',
                DESCRIPTION_3 : "Kami mengikuti Pengurusan Amalan Baik (GMP) dan memberikan anda perkhidmatan yang boleh dipercayai dan cemerlang",
                TITLE_4:'Penyelesaian Pergudangan Bersepadu',
                DESCRIPTION_4 : "Sistem kod bar lokasi storan dan Sistem Cloud WMS",
                TITLE_5:'Perkhidmatan Securiy Dipercayai',
                DESCRIPTION_5 : "Storan kami berada di bawah pengawasan CCTV 24 jam dan dapat memantau dari jauh melalui telefon bimbit",            
                TITLE_6:'Sistem Pemantauan Suhu Pintar',
                DESCRIPTION_6 : "Sistem kami disertakan dengan makluman dan pemberitahuan yang dicetuskan secara automatik melalui e-mel atau panggilan telefon apabila mengesan perubahan suhu yang tidak normal",            
            }
        },
        HISTORY:{
            HENG_HUAT:{
                H_1:'Pada tahun 2001, Encik Tee Chian Huat mengasaskan syarikat itu, yang dinamakan T HENG HWA TRADING, sebuah syarikat yang pakar dalam membekalkan buah-buahan segar dan berkualiti tinggi ke seluruh Malaysia.',
                H_2:'Pada tahun 2010, syarikat itu memulakan perniagaan borong buah-buahan dan membawa masuk buah-buahan import segar dan berkualiti tinggi dari China dan Thailand.',
                H_3:'Pada tahun 2014, syarikat itu menukar namanya kepada HENG HUAT FRUTIS SDN BHD dan telah ditubuhkan secara rasmi dan memulakan perkhidmatan runcit dan borong buah-buahan di Johor, dan skala operasi terus berkembang.',
                H_4:"Sehingga kini, kami secara konsisten memastikan kualiti terbaik, keselamatan dan kebolehkesanan asal dalam pembungkusan dan penghantaran buah-buahan kami. Kami menjual produk berkualiti segar dan bermusim pada harga premium.",
                H_5:"Anak syarikat kami EL Cold Chain Logistics Sdn Bhd lahir daripada penggunaan pembungkusan yang mematuhi standard dan selamat. Kami memastikan cara yang boleh dipercayai untuk mengangkut barangan dan sistem ulang-alik rak palet canggih menyediakan anda dengan storan berketumpatan tinggi serta sistem kawalan jauh untuk memastikan buah-buahan segar diangkut kepada pelanggan kami.",
                H_6:"Untuk mengekalkan kesetiaan pelanggan kami, kami menghormati tarikh akhir penghantaran yang ditetapkan. Pasukan profesional kami cekap dan mampu memenuhi semua keperluan khas. Pasukan ini akan membantu anda sepanjang proses pembelian dan memberikan anda perkhidmatan selepas jualan yang sangat baik, serta susulan tetap melalui telefon. Dengan kepakaran kami, pengetahuan kami dalam bidang ini dan motivasi kuat kami, HENG HUAT FRUTIS SDN BHD mencari kualiti produknya, ingin memenuhi keperluan pelanggannya, sambil memastikan keselamatan produknya untuk pengedar dan pengguna.",
            },
            HPO_FRUITS:{
                H_1:"Kami mempunyai perkhidmatan import dalam Buah-buahan Segar"
            },
            DRAGON_GLOBAL:{
                H_1:"Kami mempunyai 200 HEKTAR keluasan tanah",
                H_2:"Kami juga menyediakan perkhidmatan pemborong kepada Pedagang Tempatan dan perkhidmatan mengeksport ke Singapura, Indonesia, Dubai dan Kemboja"
            },           
        }
        
    },


}