import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import {routesObj} from '@/enums/common'
Vue.use(VueRouter);

const routes = [
  {
    path: routesObj.home.path,
    name: routesObj.home.name,
    component: Home,
  },
  {
    path: routesObj.hengHuat.path,
    name: routesObj.hengHuat.name,
    component: () =>
      import(/* webpackChunkName: "hengHuat" */ "../views/HengHuat.vue"),
  },
  {
    path: routesObj.hpo.path,
    name: routesObj.hpo.name,
    component: () =>
      import(/* webpackChunkName: "hpoFruits" */ "../views/Hpo.vue"),
  },
  {
    path: routesObj.dragonGlobal.path,
    name: routesObj.dragonGlobal.name,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/DragonGlobal.vue"),
  },
  {
    path: routesObj.elColdChain.path,
    name: routesObj.elColdChain.name,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ElColdChain.vue"),  
  }
];

let router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash
      }
    }
    return { x: 0, y: 0 }
  }
});

export default router;
