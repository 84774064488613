<template>
    <div class="company-banner">
        <div class="company-info">
            <h2>{{$t(companyTitle)}}</h2>
            <p>{{$t(companyDescription)}}</p>
        </div>
        <div class="company-image"
            :class="specificClassGenerator(actionLink)">
            <img :src="backgroundImage"/>
        </div>
        <div>
            <router-link :to="actionLink" class="action-container">
                <div class="action-link">
                {{$t('ACTION.LEARN_MORE')}}
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
import hengHuatLogo from '@/assets/logo/henghuat.jpg'
export default {
    name:"Intro",
    props:{
        backgroundImage:{
            type:String,
            default:""
        },
        actionLink:{
            type:String,
            default:""
        },
        companyTitle:{
            type:String,
            default:""
        },
        companyDescription:{
            type:String,
            default:""

        }
    },
    methods:{
        specificClassGenerator(link){
            switch(link){
                case '/el-cold-chain':
                    return 'el-group'
                case '/dragon-global':
                    return 'dragon-global'
                case '/heng-huat':
                    return 'heng-huat'
                case '/hpo-fruits':
                    return 'hpo-fruits'
                default :
                    return ''
            }

        }
    }
}
</script>

<style lang="scss" scoped>
.heng-huat{
    width: 450px;
}

.hpo-fruits{
    width: 800px;
}

.dragon-global{
    width: 550px;
}

.el-group{
    width: 600px;
}

.company-banner{
    width:100%;
    min-height:100vh;
    position: relative ;
    border-bottom: 3px solid #f9f4f4;
    padding: 15px 0;

    &:last-child{
        border-bottom: none;
    }

    .company-info {
        h2{
            font-size: 4.5rem;
            font-weight: bold;
            // text-shadow: 2px 2px 0px $action-color, 5px 4px 0px rgba(0,0,0,0.15);
            // color: $action-color;
            background: #032238;
            background: -webkit-repeating-linear-gradient(to bottom, #032238 0%, #362BCF 100%);
            background: -moz-repeating-linear-gradient(to bottom, #032238 0%, #362BCF 100%);
            background: repeating-linear-gradient(to bottom, #032238 0%, #362BCF 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        p{
            font-size: 2rem;
            // text-shadow: 2px 2px 0px $action-color, 5px 4px 0px rgba(0,0,0,0.15);
            color: $action-color;
        }
        
    }

    .company-image{
        margin: 0 auto;

        img{
            width: 100%;
        }
    }

    .action-container{
        border: 1px solid $action-color;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30%;
        padding: 15px;
        margin: 15px auto 0;
        cursor: pointer;
        transition: .2s all;
        background-color: $action-color;


        .action-link{
            color: $white;
            text-decoration: none;
            font-size: 1.5rem;
        }

        &:hover{
            background-color: $action-color;
            border: 1px solid $action-color;
            .action-link{
                color:$white
            }
        }


    }

}

@media screen and (max-width:600px){
    .el-group{
        width: 100%;    
        background-size: cover !important;
    }

    .company-banner{
        min-height: 50vh;
        .company-info{
            h2{
                font-size: 2.5rem;
            }
            p{
                font-size: 1.4rem;
            }
        }

        .company-image{
            width: 100% !important;
        }

        .heng-huat{
            width: 80% !important;
        }

        .action-container{
            width: 80%;
            padding: 5px;
            background-color: $action-color;
            border: 1px solid $action-color;
            
            .action-link{
                font-size: 1rem;
                color:$white
            }
        }
        
    }
}
</style>