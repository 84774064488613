import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import BootstrapVue from "bootstrap-vue";
import VueI18n from 'vue-i18n'
import axios from "axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { getCookie } from "./utils/cookie";
import languageFile from './language'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';


Vue.use(BootstrapVue);
Vue.use(VueI18n)
Vue.config.productionTip = false;


let baseUrl = process.env.NODE_ENV === 'development' ? "http://localhost:3000/" : "http://YourProductionServiceUrl"
let defaultLanguage = getCookie('language') || 'en'

let vuexStore = store(axios.create({
  baseURL: baseUrl
}));

vuexStore.dispatch('language/updateLanguage',{language:defaultLanguage})

let i18n = new VueI18n({
  locale: defaultLanguage,
  fallbackLocale:defaultLanguage,
  messages: languageFile,
  silentTranslationWarn:true
})

new Vue({
  router,
  store: vuexStore,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
