module.exports = [
    {
        companyTitle:"Heng Huat Fruits",
        companyDescription:"COMPANY.DESCRIPTIONS.HENG_HUAT",
        actionLink:"/heng-huat",
        backgroundImage: require('../assets/images/henghuat/heng_huat_logo.png')
    },
    {
        companyTitle:"HPO Fruits",
        companyDescription:"COMPANY.DESCRIPTIONS.HPO_FRUITS",
        actionLink:"/hpo-fruits",
        backgroundImage: require('../assets/images/hpo/hpo_logo.png')
    },
    {
        companyTitle:"Dragon Global",
        companyDescription:"COMPANY.DESCRIPTIONS.DRAGON_GLOBAL",
        actionLink:"/dragon-global",
        backgroundImage: require('../assets/images/dragonGlobal/dragon_fruit.png')
    },
    {
        companyTitle:"The EL Group",
        companyDescription:"COMPANY.DESCRIPTIONS.EL_COLD_CHAIN_LOGISTIC",
        actionLink:"/el-cold-chain",
        backgroundImage: require('../assets/images/elColdChain/el_cold_main.png')
    }
]

